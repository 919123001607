<template>
  <section class="m_lives_live">
    <div class="header">
      <div v-if="!siteLabel" class="row1">
        <img class="super_button" src="@/assets/lives/super.png" @click="openHome()" alt="" />
      </div>
      <div class="header-buttons">
        <div v-if="canFullscreen" class="button-icon" @click="toggleFullscreen()">
          <SvgIcon name="fullWindow" :size="14" />
        </div>
        <div v-else class="button-icon" @click="toggleFullwindow()">
          <SvgIcon name="fullWindow" :size="14" />
        </div>
        <div class="button-icon" @click="hideLive()">
          <SvgIcon name="back" :size="14" />
        </div>
      </div>
    </div>
    <iframe
      ref="videoIframeRef"
      class="video"
      :class="{ fullwindow: isFullwindow }"
      :src="activeLive.LiveLinkAry.slice(10)"
    />
    <div
      v-if="isFullwindow"
      class="iframe-close"
      :title="$t('lives.close')"
      @click.stop="toggleFullwindow()"
    />
    <div class="footer">
      <div class="league_box">
        <div class="catleague">
          <SvgIcon :name="getCatSvg(activeLive.CatID)" color="#00A8BF" :size="18" />
          <span>{{ activeLive.CatName }} - {{ activeLive.LeagueName }}</span>
        </div>
        <div class="time_act">{{ activeLive.TimeAct }}</div>
      </div>
      <div class="team">
        <div class="home_team">
          <div class="name">
            {{ activeLive.HomeTeamStr }}
          </div>
          <div class="score">{{ activeLive.HomeScore }}</div>
        </div>
        <div class="away_team">
          <div class="name">
            {{ activeLive.AwayTeamStr }}
          </div>
          <div class="score">{{ activeLive.AwayScore }}</div>
        </div>
      </div>
      <el-button round class="btn_bet" @click="openTable(activeLive)">
        {{ $t('lives.bet') }}
      </el-button>
    </div>
  </section>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { isSupportFullscreen } from '@/config';
  import SvgIcon from '@/components/SvgIcon.vue';
  import { getCatName } from '@/utils/gameHelper';

  export default {
    components: {
      SvgIcon,
    },
    data() {
      return {
        canFullscreen: isSupportFullscreen && screen.width < 800,
        isFullwindow: false,
      };
    },
    computed: {
      ...mapState(['siteLabel']),
      ...mapState('Lives', ['cats', 'activeLive']),
    },
    methods: {
      ...mapMutations('Lives', ['hideLive']),
      ...mapActions('Lives', ['openHome', 'openTable']),
      toggleFullscreen() {
        const { $refs } = this;
        const { videoIframeRef } = $refs;
        if (videoIframeRef) {
          videoIframeRef.requestFullscreen();
        }
      },
      toggleFullwindow() {
        this.isFullwindow = !this.isFullwindow;
      },
      getCatSvg(catID) {
        return getCatName(catID);
      },
    },
  };
</script>

<style lang="scss">
  .m_lives_live {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    width: 100%;
    height: 100%;
    color: #868686;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 52px;
      padding: 0 8px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;

      .home_button {
        width: 20px;
        cursor: pointer;
        filter: brightness(0.9);

        &:hover {
          filter: brightness(1);
        }
      }

      .super_button {
        max-width: 148px;
        width: 100%;
        cursor: pointer;
        filter: brightness(0.9);

        &:hover {
          filter: brightness(1);
        }
      }
      .header-buttons {
        display: flex;
        align-items: center;
        gap: 10px;

        .button-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          padding: 0px 8px;
          border-radius: 50%;
          color: #ffffff;
          cursor: pointer;
          background-color: #7badb0;

          &:hover {
            background-color: #095a5f;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    > .video {
      flex-grow: 1;
      flex-shrink: 0;
      width: calc(100% - 16px);
      max-height: 240px;
      border: 1px solid #000;
      background-color: #000;
      border-radius: 10px;
      margin: 10px auto;

      &.fullwindow {
        position: fixed;
        left: 0;
        top: 0;
        max-height: initial;
        width: 100%;
        height: 100%;
        margin: initial;
        border-radius: initial;
        z-index: 1;
      }
    }

    .iframe-close {
      position: fixed;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;
      z-index: 2;
      background-color: #ccc;
      transition: transform 400ms ease;
      cursor: pointer;
      mask: url('~@/assets/live/close.svg');

      &:hover,
      &:active {
        transform: scale(1.2);
      }
    }

    > .footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: calc(100% - 16px);
      padding: 15px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;

      .league_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .catleague {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #868686;
      }

      .time_act {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 8px;
        border: 1px solid #c7c7c7;
        border-radius: 8px;
        color: #868686;
        font-size: 16px;
        font-weight: 500;
        max-width: fit-content;
      }
      .team {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 15px;
        width: 100%;

        .home_team,
        .away_team {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;

          .name {
            color: #657274;
            display: flex;
            align-items: center;
            gap: 8px;
            &::before {
              content: '';
              width: 18px;
              height: 18px;
              display: inline-block;
              border-radius: 50%;
            }
          }
          .score {
            color: #00a2ad;
          }
        }
        .home_team {
          .name {
            &::before {
              background-color: #095a5f;
            }
          }
        }
        .away_team {
          .name {
            &::before {
              background-color: #7badb0;
            }
          }
        }
      }

      .btn_bet {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        border-width: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        background: linear-gradient(90deg, #108df4 0%, #00bea0 100%);
      }
    }
  }
</style>
